<style lang="scss">
    @import "~@/assets/css/var";
    .province-top {
        .top-item{
            &_title{
                font-size: 14px;
                display: flex;
                margin-top: 20px;
                .title{
                    font-weight: bold;
                }
                .export{
                    cursor: pointer;
                    padding-left: 10px;
                    &:hover{
                        color: $main;
                    }
                }
            }
            &_content{
                display: flex;
                .sale-table {
                    width: 68%;
                    .my-table{
                        height: 100%;
                        .el-table{
                            height: 96%;
                            .active {
                                color: $main;
                            }
                        }
                    }
                }
                .echart {
                    width: 32%;
                    margin-left: 20px;
                    .canvas{
                        height: 400px;
                        margin: 0 auto;
                        width: 100%;
                    }
                }
                .no-data{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                }
                .search{
                    margin-top: 10px;
                }
            }
        }
    }
</style>
<template>
    <div class="corp-sale flex-item province-top scroll-div" style="padding: 0;">
        <div class="" style="padding:0 20px 20px;" v-if="loaded" v-loading="loading">
            <template v-if="message">
                <ts-error></ts-error>
            </template>
            <template v-else>
                <div class="top-item">
                    <div class="top-item_title">
                        <div class="title">药品销售top30</div>
                        <span @click="exportInfo()" class="export"><i class="el-icon-document"></i>导出数据</span>
                    </div>
                    <div class="top-item_content">
                       
                        <div class="sale-table">
                            <div class="search">
                                筛选：
                                <el-select v-model="provinceCode" @change="initData">
                                    <el-option v-for="item in provinceAll" :key="item.id" 
                                        :value="item.id" :label="item.name">
                                    </el-option>
                                </el-select>
                            </div>
                            <ts-table ref="table" style="padding: 0" :cols="cols" zero-to-empty 
                                :data="list" height="400px" hide-detail hide-page>
                                <template v-slot:slot_trend="{ props }">
                                    <div @click="showCanvas(props)" class="icon" :class="{'active': index === props.$index}">
                                        <i class="iconfont icon-a-rongqi1"></i>
                                    </div>
                                </template>
                            </ts-table>
                        </div>
                        <div class="echart" v-if="list.length !== 0" style="margin-top: 52px;">
                            <div :id="nameKey" class="canvas"></div>
                        </div>
                        <div v-else class="no-data">
                            <ts-empty></ts-empty>
                        </div>
                    </div>
                </div>
                <div class="top-item">
                    <div class="top-item_title">
                        <div class="title">企业药品销售top30</div>
                        <span @click="exportCorpInfo()" class="export"><i class="el-icon-document"></i>导出数据</span>
                    </div>
                    <div class="top-item_content" v-loading="corp.loading">
                        <div class="sale-table">
                            <ts-table ref="table" style="padding: 0" :cols="corp.cols" zero-to-empty
                                :data="corp.list" height="400px" hide-detail hide-page>
                                <template v-slot:slot_trend="{ props }">
                                    <div @click="showCanvasCorp(props)" class="icon" :class="{'active': corp.index === props.$index}">
                                        <i class="iconfont icon-a-rongqi1"></i>
                                    </div>
                                </template>
                            </ts-table>
                        </div>
                        <div class="echart" v-if="corp.list.length !== 0" style="margin-top: 20px;">
                            <div :id="nameKeyCorp" class="canvas"></div>
                        </div>
                        <div v-else class="no-data">
                            <ts-empty></ts-empty>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['visitId'],
        data() {
            return {
                loaded: true,
                nameKey: 'newProvinceTop',
                nameKeyCorp: 'corpInvTop',
                index: 0,
                cols: this.$help.generalCols([
                    this.$variable.indexColumn.drug({listOrderView: '否'}),
                    ...this.$variable.years.map(item => ({
                        fieldName: item.value.toString(),
                        name: item.value + '年',
                        listOrderView: true,
                        width: 100,
                        listClass: 'tc'
                    })),
                    {
                        fieldName: 'trend',
                        name: '趋势图',
                        width: 60,
                        listQueryView: '否',
                        // listQueryModel: 1,
                    }
                ]),
                sourceList: [],
                list: [],
                message: '',
                loading: true,
                year: this.$variable.lastYear.key,

                provinceCode: '',
                provinceAll: [],

                corp: {
                    index: 0,
                    cols: this.$help.generalCols([
                        {
                            fieldName: 'comName',
                            name: '药品',
                            width: 50,
                            listQueryView: '是',
                            listQueryModel: 1,
                            // listStipulate: 1list
                        },
                        {
                            fieldName: 'unifySpec',
                            name: '规格',
                            width: 40,
                            listQueryView: '是',
                            listQueryModel: 1,
                            // listStipulate: 1
                        },
                        {
                            fieldName: 'compName',
                            name: '企业',
                            width: 80,
                            listQueryView: '是',
                            listQueryModel: 1,
                            // listStipulate: 1
                        },
                        ...this.$variable.years.map(item => ({
                            fieldName: item.value.toString(),
                            name: item.value + '年',
                            listOrderView: true,
                            width: 50,
                            listClass: 'tc'
                        })),
                        {
                            fieldName: 'trend',
                            name: '趋势图',
                            width: 40,
                            listQueryView: '否',
                            // listQueryModel: 1,
                        }
                    ]),
                    list: [],
                    loading: true,
                }
            }
        },
        created() {
            // 加载所有省份
            this.$api.get('setting/sysBaseArea/querySysBaseAreaList', {parentId: '0'})
                .then(res => {
                    this.provinceAll = res.data
                    this.provinceCode = res.data[0].id
                    this.initData()
                })
        },
        methods: {
            initData() {
                this.packageData()
                this.getCorpList()
            },
            getQuery() {
                return {
                    // yearKey: this.year,
                    provinceCode: this.provinceCode
                }
            },
            packageData() {
                this.loading = true
                // province/aimDrugsSaleYearCity/sumYearAmountProvinceTop30 (旧)
                this.$api.get('province/saleProvince/sumYearAmountProvinceTop30All', this.getQuery(), {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.code === this.$variable.successCode) {
                            this.list = res.data.map(item => {
                                this.$variable.years.forEach(year => {
                                    item[`ratio${year.key}`] = this.$help.parseFloat100(item[`ratio${year.key}`])
                                })
                                return item
                            })
                            if(res.data.length !== 0) {
                                this.initEchart(res.data[0])
                            }
                        } else {
                            this.message = true
                        }
                    })
                    .finally(() => {this.loading = false})
            },
            getCorpList() {
                this.corp.loading = true
                this.$api.get('province/saleProvince/sumYearAmountComCompSpecProvinceTop30', this.getQuery(), {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.code === this.$variable.successCode) {
                            this.corp.list = res.data.map(item => {
                                this.$variable.years.forEach(year => {
                                    item[`ratio${year.key}`] = this.$help.parseFloat100(item[`ratio${year.key}`])
                                })
                                return item
                            })
                            if(res.data.length !== 0) {
                                this.initCorpEchart(res.data[0])
                            }
                        } else {
                            this.message = true
                        }
                    })
                    .finally(() => {this.corp.loading = false})
            },
            //药品销售top30
            exportInfo(txt = '确定导出吗', query = {}) {
                this.$store.commit('confirm', {
                    content: txt, //province/aimDrugsSaleYearCity/exportSumYearAmountProvinceTop30 (旧)
                    callback:(next, back) => {
                        this.$api.get('province/saleProvince/exportSumYearAmountProvinceTop30All', this.$help.joinObj( this.getQuery(), query))
                            .then(res => {
                                if (!(res.success || res.code === 'N0001')) {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === 'N0001') {
                                    next()
                                    this.exportInfo(res.message, {force: true})
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            },
            //企业药品销售top30
            exportCorpInfo(txt = '确定导出吗', query = {}) {
                this.$store.commit('confirm', {
                    content: txt,
                    callback:(next, back) => {
                        this.$api.get('province/saleProvince/exportSumYearAmountComCompSpecProvinceTop30', this.$help.joinObj( this.getQuery(), query))
                            .then(res => {
                                if (!(res.success || res.code === 'N0001')) {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === 'N0001') {
                                    next()
                                    this.exportCorpInfo(res.message, {force: true})
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            },
            initEchart(row) {
                let list = []
                this.$variable.years.forEach(item=>{
                    list.push({
                        name: item.value,
                        amount: row[item.value],
                    })
                })
                this.$nextTick(()=>{
                    this.renderBarOnly(this.nameKey, list, row.comName)
                })
            },
            initCorpEchart(row) {
                let list = []
                this.$variable.years.forEach(item=>{
                    list.push({
                        name: item.value,
                        amount: row[item.value],
                    })
                })
                this.$nextTick(()=>{
                    this.renderBarOnly(this.nameKeyCorp, list, `${row.compName}-${row.comName}-${row.unifySpec}`)
                })
            },
            //药品销售top30趋势图
            showCanvas(props) {
                this.index = props.$index
                // console.log(props)
                this.initEchart(props.row)
            },

            showCanvasCorp(props) {
                this.corp.index = props.$index
                // console.log(props)
                this.initCorpEchart(props.row)
            },

            //柱状图
            renderBarOnly (el, data, comName) {
                let option = {};
                if(data.length !== 0) {
                    let xAxisData = [], seriesData = [];
                    data.forEach(item=>{
                        xAxisData.push(item.name)
                        seriesData.push(item.amount)
                    })
                    option = {
                        title: {
                            text: comName,
                            x:'center',
                            y:'top',
                            textAlign:'left',
                            textStyle: {
                                fontSize: 14
                            }
                        },
                        xAxis: {
                            data: xAxisData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        grid: {
                            bottom: 30,
                            right: 10
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        series: [
                            { data: seriesData, type: 'bar', barWidth: 25, }
                        ]
                    };
                }else { 
                    option = {
                        title: {
                            show: true,
                            text: '暂无数据',
                            left: 'center',
                            top: 'center'
                        },
                    }
                }
                const myChart = window.echarts.init(document.getElementById(el))
                myChart.clear()
                myChart.setOption(option)
                window.addEventListener('resize', function () {
                    myChart.resize()
                })
            },
        }
    }
</script>